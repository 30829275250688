import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { IoLogoLinkedin, IoLogoInstagram } from 'react-icons/io5';

import { Form, Icon, Loader } from 'components';

import { linkIcon } from 'assets/icons';

import {
  logoDark,
  whtspcLogo,
  footerBackground
} from 'assets/images';

import styles from './Footer.module.scss';

interface SubFormData {
  email: string;
}

const Footer: React.FC = () => {
  const handleValidate = (formData: SubFormData) => !!formData.email;

  // Submit subscriber form
  const handleSubscribe = async (formData: SubFormData) =>
    await axios({
      method: 'post',
      url: `https://${process.env.REACT_APP_BASE_URL}/subscribe.php`,
      headers: { 'content-type': 'application/json' },
      data: formData
    });

  return (
    <footer id='footer' className='center' style={{ backgroundImage: `url(${footerBackground})` }}>
      <h2 className='primary'>Get in touch for <br />a <span>free demo</span></h2>

      <div className={styles.buttonGroup}>
        <a type='primary' href='/contact' className={styles.signup}>Contact us</a>
      </div>

      <div className={styles.footerContainer}>
        <div className={styles.footerNavigation}>
          <div className={styles.column}>
            <h3>Information</h3>
            <ul>
              <li>
                <Link to='/' className='link'>Home</Link>
              </li>
              <li>
                <Link to='/pricing' className='link'>Pricing guide</Link>
              </li>
              <li>
                <Link to='/contact' className='link'>Get in touch</Link>
              </li>
              <li>
                <a href='https://app.recruithero.com.au/' className='link'>Sign up</a>
              </li>
            </ul>
          </div>

          <div className={styles.column}>
            <h3>Product</h3>
            <ul>
              <li>
                <a href='https://auth.recruithero.com.au/whtspctimeb2c.onmicrosoft.com/b2c_1a_signup_signin/oauth2/v2.0/authorize?client_id=314b02df-130e-42d0-93b3-9ed38f1bfad7&scope=https%3A%2F%2Fwhtspctimeb2c.onmicrosoft.com%2Fapi%2Fall%20openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Fapp.recruithero.com.au%2Faccount-signup&client-request-id=4cddf4de-9540-4bf3-b06b-2599ee8715a7&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.22.1&x-client-OS=&x-client-CPU=&client_info=1&code_challenge=ZEeLTRWb7E24KL-j6rhucZdM1TUgirw8bTpNsCP28Zk&code_challenge_method=S256&nonce=4ef77284-cd64-4ef0-ba15-f171df6198a3&state=eyJpZCI6IjBlYWI0ZmJlLTAwOTAtNDY1NS1hMTg4LTBiNmQzYmE1MTU0YSIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3D' className='link'>Login</a>
              </li>
              <li>
                <a href='https://guides.recruithero.com.au/' className='link'>Guides</a>
              </li>
            </ul>
          </div>

          <div className={styles.column}>
            <h3>Legal</h3>
            <ul>
              <li>
                <Link to='/terms' className='link'>Terms &amp; conditions</Link>
              </li>
              <li>
                <Link to='/privacy' className='link'>Privacy policy</Link>
              </li>
            </ul>
          </div>

          <div className={styles.column}>
            <h3>Social</h3>
            <ul>
              <li>
                <a className='link' href='//www.linkedin.com/company/whtspcstudio/' target='_blank' rel='noreferrer'>LinkedIn</a>
              </li>
              <li>
                <a className='link' href='//www.instagram.com/whtspc_studio/' target='_blank' rel='noreferrer'>Instagram</a>
              </li>
            </ul>
          </div>

        </div>
        <Form<SubFormData>
          onValidate={handleValidate}
          onSubmitAsync={handleSubscribe}
          className={styles.subscribeForm}>
          {({ formData, formSubmitted, formError, formLoading, setFieldValue }) =>
            (
              <>
                <h3>Subscribe for product updates</h3>

                <Loader isLoading={formLoading}>
                  <div className={styles.formContainer}>
                    <input
                      required
                      type='email'
                      readOnly={formSubmitted}
                      placeholder='Enter your email address'
                      value={formData.email ?? ''}
                      className={formError ? styles.error : undefined}
                      onChange={e => setFieldValue('email', e.target.value)} />

                    <button
                      disabled={formSubmitted || formLoading}
                      type='submit'
                    >
                      Sign up <Icon img={linkIcon} />
                    </button>
                  </div>
                </Loader>
              </>
            )}
        </Form>
      </div>

      <div className={styles.footnote}>
        <Link to='/'>
          <img
            src={logoDark}
            alt='recruithero'
            className={styles.logo} />
        </Link>

        <span className={styles.copy}><span>built by&nbsp;</span>
          <a
            href='//whtspc.com.au/'
            target='_blank'
            rel='noreferrer'>
            <img
              alt='whtspc'
              src={whtspcLogo} />
          </a>
        </span>

        <ul className={styles.socialList}>
          <li>
            <a href='//www.instagram.com/whtspc_studio/' target='_blank' rel='noreferrer'>
              <IoLogoInstagram />
            </a>
          </li>
          <li>
            <a href='//www.linkedin.com/company/whtspcstudio/' target='_blank' rel='noreferrer'>
              <IoLogoLinkedin />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;