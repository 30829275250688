import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { logo } from 'assets/images';
import './NavigationMenu.scss';

/**
 * NavigationMenu
 *
 * @description Navigation header
 */
const NavigationMenu: React.FC = () => {
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);

  return (
    <header>
      <div className='navigation-header'>
        <div className='navigation-header__inner'>
          <Link to='/'>
            <img
              className='navigation-header__logo'
              src={logo}
              alt='recruithero' />
          </Link>

          <nav className='navigation-header__menu'>
            <ul>
              <li><Link to=''>Home</Link></li>
              <li><Link to='/features'>Features</Link></li>
              <li><Link to='/pricing'>Pricing</Link></li>
              <li><Link to='/for-contractors'>For contractors</Link></li>
            </ul>
          </nav>

          <button
            className={`navigation-header__hamburger-menu ${isMenuActive ? 'navigation-header__hamburger-menu--active' : ''}`}
            onClick={() => setIsMenuActive(!isMenuActive)}
          >
            <div className='navigation-header__menu-bar' />
          </button>
        </div>
      </div>

      <div className={`mobile-menu ${isMenuActive ? 'mobile-menu--active' : ''}`}>
        <div className='mobile-menu__overlay' onClick={() => setIsMenuActive(!isMenuActive)} />
        <div className='mobile-menu__inner'>
          <button
            className='mobile-menu__close-button'
            onClick={() => setIsMenuActive(!isMenuActive)}
          />
          <nav className='mobile-menu__navigation'>
            <ul>
              <li><Link to=''>Home</Link></li>
              <li><Link to='/features'>Features</Link></li>
              <li><Link to='/pricing'>Pricing</Link></li>
              <li><Link to='/for-contractors'>For contractors</Link></li>
              <li><Link to='/contact'>Get in touch</Link></li>
              <li><Link to='/terms'>Terms &amp; conditions</Link></li>
              <li><Link to='/privacy'>Privacy policy</Link></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default NavigationMenu;