import React from 'react';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import { FeatureList } from 'components/common';
import {
  Footer,
  FeatureSection,
  SplitSection,
  NavigationMenu,
  BasicSplitSection
} from 'components/layout';

import {
  businessIcon,
  enterpriseIcon,
  essentialsIcon,
  smeIcon,
  startupIcon
} from 'assets/icons';

import {
  pricingHeaderImage,
  pricingSecondaryImage
} from 'assets/images';

/**
 * Pricing
 *
 * @description Pricing page
 */
const Pricing: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <NavigationMenu />

      <header>
        <SplitSection
          title={<><span>Pricing should be simple</span> and should scale with your business</>}
          content='We offer a range of features to support your business functions: contractor onboarding, timesheet approvals and reporting. Our feature set is always growing as we try deliver the features you need.'
          image={pricingHeaderImage}
          contentLeftAligned
          action={{
            label: 'See our pricing',
            link: '#pricingList'
          }}
        />
      </header>
      <main>
        <FeatureSection
          id='pricingList'
          title={<>We designed our pricing to be <span>simple to understand</span>, and to scale with your business</>}
          description='All of our prices are GST inclusive'
        >
          <FeatureList
            small
            items={[
              {
                title: <>Startup<span> $44 / month</span></>,
                subTitle: '5 contractors',
                alt: 'Startup',
                img: startupIcon,
                content: 'Supercharge your startup and get off the ground with an easy timesheeting solution.'
              },
              {
                title: <>Essentials<span> $88 / month</span></>,
                subTitle: '20 contractors',
                alt: 'Essentials',
                img: essentialsIcon,
                content: 'Timesheeting essentials for your recruiting business.'
              },
              {
                title: <>Business<span> $275 / month</span></>,
                subTitle: '100 contractors / month',
                alt: 'Business',
                img: businessIcon,
                content: 'Great for small and medium businesses that want room to grow.'
              },
              {
                title: <>SME<span> $440 / month</span></>,
                subTitle: '200 contractors / month',
                alt: 'SME',
                img: smeIcon,
                content: 'Perfectly suited to the large scale needs of your business.'
              },
              {
                title: 'Enterprise',
                subTitle: '200+ contractors',
                alt: 'enterprise',
                img: enterpriseIcon,
                content: 'Need something bigger? Get in touch and we can make a custom plan for your need.'
              }
            ]}
          />
        </FeatureSection>

        <BasicSplitSection
          title={<>Help pilot our app early and get <span>3 months free</span> on your subscription</>}
          content='Our product is perfect for early adopters, we&apos;ll reward you for joining early with 3 months on us.'
          image={pricingSecondaryImage}
          fullWidth
          action={{
            label: 'Tell us about it',
            icon: <IoPaperPlaneOutline />,
            onClick: () => history.push('/contact')
          }}
        />
      </main>
      <Footer />
    </>
  );
};

export default Pricing;