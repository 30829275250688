import React from 'react';

import Button from 'components/Button';

import './BasicSplitSection.scss';

/**
 * ISplitSection
 *
 * @description Component props
 */
interface ISplitSection {
  className?: string;
  title: string | React.ReactNode;
  content: string;
  image: string;
  imageAlt?: string;
  contentLeftAligned?: boolean;
  fullWidth?: boolean;
  action?: {
    label: string;
    icon?: React.ReactNode;
    link?: string;
    onClick?: () => void;
  };
}

/**
 * SplitSection
 *
 * @description Split section component
 */
const BasicSplitSection: React.FC<ISplitSection> = ({ title, content, image, imageAlt, contentLeftAligned, action, fullWidth, className = '' }) => (
  <div className={
    `basic-split-section ${className}` +
    ` ${contentLeftAligned ? 'basic-split-section--left-justified' : ''}` +
    ` ${fullWidth ? 'basic-split-section--full-width' : ''}`
  }>
    <div className='basic-split-section__column'>
      <div className='basic-split-section__background' />
      <img
        className='basic-split-section__image'
        src={image}
        alt={imageAlt}
      />
    </div>
    <div className='basic-split-section__column basic-split-section__column--content'>
      <div className='basic-split-section__content'>
        <h2 className='basic-split-section__title'>
          {title}
        </h2>
        <p>{content}</p>
        {
          action &&
            <Button
              type='primary'
              className='basic-split-section__button'
              {...action}
            />
        }
      </div>
    </div>
  </div>
);

export default BasicSplitSection;