import React from 'react';

import Button from 'components/Button';

import { headerBackground } from 'assets/images';
import './SplitSection.scss';

/**
 * ISplitSection
 *
 * @description Component props
 */
interface ISplitSection {
  className?: string;
  title: string | React.ReactNode;
  content: string;
  image: string;
  imageAlt?: string;
  contentLeftAligned?: boolean;
  action?: {
    label: string;
    icon?: React.ReactNode;
    link?: string;
    onClick?: () => void;
  };
}

/**
 * SplitSection
 *
 * @description Split section component
 */
const SplitSection: React.FC<ISplitSection> = ({ title, content, image, imageAlt, contentLeftAligned, action, className = '' }) => (
  <div className={`split-section ${contentLeftAligned ? 'split-section--left-justified' : ''} ${className}`}>
    <div className='split-section__column'>
      <div className='split-section__background' style={{ backgroundImage: `url(${headerBackground})` }} />
      <img
        className='split-section__image'
        src={image}
        alt={imageAlt}
      />
    </div>
    <div className='split-section__column split-section__column--content'>
      <div className='split-section__content'>
        <h2 className='split-section__title'>
          {title}
        </h2>
        <p>{content}</p>
        {
          action &&
            <Button
              type='primary'
              className='split-section__button'
              {...action}
            />
        }
      </div>
    </div>
  </div>
);

export default SplitSection;