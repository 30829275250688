import React from 'react';

import { Header, Footer } from 'components/layout';
import { Align } from 'types/enums';

const NotFound: React.FC = () => {
  return (
    <>
      <Header alignment={Align.Center} >
        <h1>Whoops... There&apos;s <strong>nothing</strong> to see here</h1>
      </Header>
      <Footer />
    </>
  );
};

export default NotFound;